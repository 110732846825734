.toast-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(100deg,rgba(255, 255, 255, 0.95), rgba(232, 254, 255, 0.95), rgba(255, 253, 253, 0.7));
    padding: 5px;

    width: 40%;
    min-width: 250px;

    box-shadow: var(--regular-shadow);
    

    border-radius: 10px;
    transition: all 1s ease;
    z-index: 10;
}

.toast-container.hidden {
    opacity: 0;
    z-index: -1;
    transition: opacity 1s ease, z-index 1s ease 1s;
}

.toast-header {
    display: flex;
    color: var(--text);
    align-items: center;
    font-weight:  bold;
    font-size: 0.8rem;
}

.toast-header > p {
    margin: 0;
}

p.toast-close {
    padding: 5px;
    margin-right: 10px;
    cursor: pointer;
    color: black;
}

.toast-main {
    font-family: Consolas,monaco,monospace;
    font-size: 0.75rem; 
}