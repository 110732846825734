.bible-container {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1;

    align-items: center;
    
    color: var(--text);
    background-color: var(--background);

    background-size: cover;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
}

.bible-wrapper{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    backdrop-filter: blur(30px);
    margin-top: 60px;
}

.bible-header {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 500px;
    align-self: center;
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.9);
}

.bible-header h4 {
    margin: 0;
    color: var(--primary);
}

.bible-date {
    margin: 0;
    padding: 3px;
    font-family: var(--secondary-font);
    font-size: 0.8rem;
    font-weight: 400;
}

.bible-selector {
    display: flex;
    margin-top: 15px;
    color: var(--primary);
    align-items: center;
}

.bible-selector select {
    padding: 3px;
    border: none;
    font-family: var(--secondary-font);
    font-weight: 500;
    max-width: 90%;
    background-color: #ffffff00;
}

.bible-pg {
    margin: 0;
    align-self: flex-end;
    font-family: var(--primary-font);
    font-weight: 400;
    font-size: 0.7rem;
}

.bible-main-wrapper {
    width: 90%;
    max-width: 500px;
    align-self: center;
    display: flex;
    flex-direction: column;
}

.bible-main {
    display: flex;
    width: 90%;
    align-self: flex-end;
    flex-direction: column;
    margin-top: 10px;
    padding: 10px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.9);
}

.bible-main h3 {
    margin: 0;
    color: var(--primary);
}

.bible-scrip {
    margin: 0;
    padding: 3px;
    border: none;
    font-family: var(--secondary-font);
    color: var(--text-dark);
    font-weight: 500;
}

.bible-pgLeft, .bible-pgRight {
    position: fixed;
    bottom: 0;
    margin-bottom: 5%;
    background-color: var(--primary);
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-light);
    border-radius: 50%;
    opacity: 0.8;
}

.bible-pgLeft {
    left: 0;
    margin-left: 5%;
}

.bible-pgRight {
    right: 0;
    margin-right: 5%;
}

.bible-pgLeft svg, .bible-pgRight svg {
    font-size: 1.6rem;
}
