.login-container {
    display: flex;
    flex-direction: column;
    flex: 1;

    justify-content: center;
    color: var(--text);
    background-color: var(--background);

    position: relative;
}

.login-card {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    height: auto;
    width: 60%;
    background-color: var(--background-alt);
    padding: 10px;
    margin-left: auto;
    margin-right: auto;

    box-shadow: var(--regular-shadow);
}

.login-card-header, .login-card-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-card-header > h1 {
    color: var(--primary);
}

.login-card-main {
    display: flex;
    flex-direction: column;
}

.login-button {
    align-self: center;
}

.login-links {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin-top: 5px;
}

.login-links > a {
    color: var(--text);
    font-size: 0.8rem;
    font-weight: 300;
}



@media only screen and (max-width: 600px) {
    .login-card {
        width: 90%;
    }
  }