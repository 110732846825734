@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}
body{

  --primary: #E62727;
  --primary-variant: #FF4545;

  /* --secondary: #841BF7; */
  /* --secondary-variant: #451AED; */
  
  /* --highlight: #ED1A7B; */
  --background: #ffffff;
  --nav-background: rgba(255,255,255,0.82);

  --text-dark: #0e0e0e;
  --text: #828282;
  --text-light: #ffffff;

  --link: #2879BF;

  --error: rgba(241, 162, 14, 0.945);

  --primary-font:'Roboto', sans-serif;
  --secondary-font: 'Montserrat', sans-serif;
  --regular-shadow: 0px 3px 6px 0px rgba(0,0,0,0.32);
  --secondary-shadow: 10px 3px 6px 1px rgba(0,0,0,0.32);
}


html,
body, #root {
    width: 100%;
    height: 100%;

    font-family: var(--primary-font);
    font-weight: 300;
}

.App {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    min-height: 100%;
    height: auto;
    overflow: hidden;
}
.login-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex: 1 1;
            flex: 1 1;

    -webkit-justify-content: center;

            justify-content: center;
    color: var(--text);
    background-color: var(--background);

    position: relative;
}

.login-card {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-width: 200px;
    height: auto;
    width: 60%;
    background-color: var(--background-alt);
    padding: 10px;
    margin-left: auto;
    margin-right: auto;

    box-shadow: var(--regular-shadow);
}

.login-card-header, .login-card-footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.login-card-header > h1 {
    color: var(--primary);
}

.login-card-main {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.login-button {
    -webkit-align-self: center;
            align-self: center;
}

.login-links {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    margin-top: 5px;
}

.login-links > a {
    color: var(--text);
    font-size: 0.8rem;
    font-weight: 300;
}



@media only screen and (max-width: 600px) {
    .login-card {
        width: 90%;
    }
  }
.button-container {
    display: -webkit-flex;
    display: flex;
    color: var(--text-light);
    font-weight: bold;
    padding: 10px;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: var(--primary);
    cursor: pointer;
    border-radius: 10px;
}

.button-container:hover {
    background-color: var(--primary-variant);
}

.button-container.large {
    min-height: 75px;
}




@media only screen and (max-width: 600px) {
    .button-container.large {
        min-height: 60px;
    }
  }
.custom-input-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-bottom: 10px;
}

.custom-input-container > label {
    font-weight: 500;
}

.custom-input-container > input {
    outline: none;
    border: none;
    padding: 5px;
    color: var(--text-dark);
    font-family: var(--secondary-font);
    font-weight: 500;
    border-bottom: 2px solid var(--primary);

    background-color: #fafafa;
}

.custom-input-container > input:focus {
    background-color: rgb(233, 233, 233);
}

@media only screen and (max-width: 600px) {
    .custom-input-container > input {
        border-bottom: 1px solid var(--primary);
    }
  }
.book-container {
    stroke: var(--primary);

    width: 200px;
    height: 200px;

    background-color: blue;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    -webkit-align-self: center;
            align-self: center;
}

.book-container svg {
   width: 100%;
   height: auto;
}

#e1, #e2 {
    fill: var(--primary);
}

 #page3, #page4, #page5, #page6 {
    opacity: 0;
}



#page3 {
    -webkit-animation-name: p3;
            animation-name: p3;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
}

#page4 {
    -webkit-animation-name: p3;
            animation-name: p3;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
}

#page5 {
    -webkit-animation-name: p3;
            animation-name: p3;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
}

#page6 {
    -webkit-animation-name: p3;
            animation-name: p3;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
}

@-webkit-keyframes p3 {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }

    30% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@keyframes p3 {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }

    30% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}




.signup-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex: 1 1;
            flex: 1 1;

    -webkit-justify-content: center;

            justify-content: center;
    color: var(--text);
    background-color: var(--background);
}

.signup-card {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-width: 200px;
    height: auto;
    width: 60%;
    background-color: var(--background-alt);
    padding: 10px;
    margin-left: auto;
    margin-right: auto;

    box-shadow: var(--regular-shadow);
}

.signup-card-header, .signup-card-footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.signup-card-header > h1 {
    color: var(--primary);
}

.signup-card-main {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.signup-control {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

.signup-button {
    min-width: 200px;
    width: 40%;
    -webkit-align-self: center;
            align-self: center;
}

.signup-links {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    margin-top: 5px;
}

.signup-links > a {
    color: var(--text);
    font-size: 0.8rem;
    font-weight: 300;
}

.signup-input {
    width: 100%;
    margin-bottom: 8%;
}

.signup-location {
    display: -webkit-flex;
    display: flex;
}

.signup-location svg{
    margin: 10px;
    font-size: 2rem;
    color: var(--primary);
    cursor: pointer;
}

.uploader-error {
    color: var(--error);
    font-size: 0.8rem;
}

.signup-uploader {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.signup-uploader img {
    width: 70px;
    padding: 5px;
    border-radius: 50%;
}




@media only screen and (max-width: 600px) {
    .signup-card {
        width: 90%;
    }

    .signup-button {
        min-width: 100px;
        -webkit-align-self: center;
                align-self: center;
    }
  }
input.uploader-input {
    display: none;
}

.uploader-container {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.uploader-label {
    width: 100%;
}

.uploader-label p {    
    margin: 0;
    font-weight: 500;
}

.uploader-output {
    color: var(--text);
    font-family: var(--secondary-font);
    font-weight: 500;
    font-size: 0.9rem;
    border-bottom: 2px solid var(--primary);

    background-color: #fafafa;
}

.uploader-progress {
    height: 3px;
    margin-top: 3px;
    border-radius: 10px;
    background-color: rgba(36, 216, 12, 0.938);
}

.upload-button {
    font-size: 0.7rem;
    padding: 5px;
    margin: 0;
    height: 90%;
    border-radius: 3px;
    -webkit-align-self: center;
            align-self: center;
}

@media only screen and (max-width: 600px) {
    .uploader-output  {
        border-bottom: 1px solid var(--primary);
    }
  }
.l-container {
    position: fixed;
    top: 0;
    left: 0;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);

}

.l-container.hidden {
    display: none;
}

.l-balls svg {
    width: 100px;
}

[id="Ball 1"] {
    animation: pulse 0.6s alternate-reverse 0s infinite;
}

[id="Ball 2"] {
    animation: pulse 0.6s alternate-reverse 0.1s infinite;
}
[id="Ball 3"] {
    animation: pulse 0.6s alternate-reverse 0.2s infinite;
}
[id="Ball 4"] {
    animation: pulse 0.6s alternate-reverse 0.3s infinite;
}
[id="Ball 5"] {
    animation: pulse 0.6s alternate-reverse 0.4s infinite;
}
[id="Ball 6"] {
    animation: pulse 0.6s alternate-reverse 0.5s infinite;
}
[id="Ball 7"] {
    animation: pulse 0.6s alternate-reverse 0.6s infinite;
}

@-webkit-keyframes pulse{
    from{
        fill: rgba(0, 0, 0, 0);
    }
    to{
        fill: var(--primary);
    }
}

@keyframes pulse{
    from{
        fill: rgba(0, 0, 0, 0);
    }
    to{
        fill: var(--primary);
    }
}
.home-container {
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex: 1 1;
            flex: 1 1;

    -webkit-align-items: center;

            align-items: center;
    
    color: var(--text);
    background-color: var(--background);
}

.home-card {
    margin-top: 60px;
    width: 90%;
    max-width: 500px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(6, 1fr);

    box-shadow: var(--regular-shadow);

    padding: 5px;
}

.home-card-left {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    grid-row: 1 / 5;
}

.home-card-right {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    grid-row: 1 / 5;

    /* font-family: var(--secondary-font); */
    padding: 5px;
}

.home-card-right p, .home-card-right h4, .home-card-right span{
    margin: 0;
    padding: 3px;
}


.home-card-footer {
    display: -webkit-flex;
    display: flex;
    grid-column: 1 / 3;
    grid-row: 5 / 7;
}

.home-card-profile {
    width: 90%;
    padding-top: 90%; /* 1:1 Aspect Ratio */

    border-radius: 100%;
    
    background-size: cover;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
}

.home-card-date {
    font-weight: 600;  
}

.home-card h4 {
    color: var(--primary);
}

.home-card-title {
    color: var(--text-dark);
    font-weight: 500;
}

.home-card span {
    font-size: 0.9rem;
}

.home-card-downloadables {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    border-right: 1px solid #ccc;
    padding-left: 5px;
}

.home-card-downloadables h4 {
    margin: 0;
    padding: 10px;
    -webkit-align-self: flex-end;
            align-self: flex-end;
}

.home-card-downloadables a {
    font-size: 0.9rem; 
}

.home-card-button {
    width: 30%;
    padding: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.home-card-button button {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: var(--primary);
    border: none;
    color: #fff;
    font-family: var(--primary-font);
    font-weight: 500;
    box-shadow: var(--regular-shadow);
}

.home-card-button button:active {
    background-color: var(--primary-variant);
}

/* ----------will be converted to components ------------*/


.home-bible-reading {
    margin: 4px;
    color: var(--text-light);
    font-size: 0.9rem;
    font-weight: 400;
}

.home-bible-reading.small {
    font-size: 0.7rem;
}

.home-timezone {
    margin: 4px;
    margin-top: 10px;
    color: var(--text-light);
    font-size: 0.9rem;
    font-weight: 500;
}

.home-timezone.small {
    margin-top: 4px;
    font-size: 0.7rem;
    font-weight: 400;
}

/* ------------------------------------------------------ */

@media only screen and (max-width: 600px) {
    .home-card-date {
        font-size: 0.8rem;
    }

    .home-card h4 {
        font-size: 0.9rem;
    }

    .home-card-title {
        font-size: 0.8rem;
    }

    .home-card span {
        font-size: 0.7rem;
    }

    .home-card-downloadables a {
        font-size: 0.7rem; 
    }

    .home-card-button button {
        width: 75px;
        height: 75px;
    }

  }

.nav-container {
    display: -webkit-flex;
    display: flex;
    position: fixed;

    width: 100%;
    color: var(--primary);
    -webkit-align-items: center;
            align-items: center;
    background-color: var(--nav-background);
    font-family: var(--primary-font);
    box-shadow: var(--regular-shadow);
    font-weight: 500;

    z-index: 1;
}

.nav-container.hide {
    display: none;
}

.nav-container img {
    width: 45px;
    padding: 5px;
    border-radius: 50%;

    margin-right: 10px;
    /* margin-top: 10px; */
    cursor: pointer;
    position: absolute;
    right: 0;
}

.nav-pic-container {
    width: 45px;
    height: 45px;

    margin-right: 10px;
    /* margin-top: 10px; */
    cursor: pointer;
    position: absolute;
    right: 0;
}


.nav-profile-pic {
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */

    border-radius: 100%;
    
    background-size: cover;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
}

.nav-item {
    display: -webkit-flex;
    display: flex;
    /* padding: 5px; */
}

.nav-item.header {
    margin-left: auto;
    margin-right: auto;
}

.nav-item.footer {
    margin-left: 10px;
    /* margin-top: 10px; */
    cursor: pointer;
    position: absolute;
    left: 0;
}

.nav-item.footer:hover {
    color: #fff;
}
.sidebar-container {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;


    z-index: 1000;
    background-color: rgba(0,0,0,0.3);
    transition: background-color 0.2s ease 0.2s, -webkit-transform 0.2s ease;
    transition: background-color 0.2s ease 0.2s, transform 0.2s ease;
    transition: background-color 0.2s ease 0.2s, transform 0.2s ease, -webkit-transform 0.2s ease;
    
}

.sidebar-container.none {
    background-color: rgba(0,0,0,0);
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    transition: background-color 0.15s ease, -webkit-transform 0.5s ease 0.2s;
    transition: background-color 0.15s ease, transform 0.5s ease 0.2s;
    transition: background-color 0.15s ease, transform 0.5s ease 0.2s, -webkit-transform 0.5s ease 0.2s;
}

.sidebar-bar {
    position: fixed;
    width: 80%;
    height: 100%;
    max-width: 500px;
    background-color: var(--background);
}

.sidebar-bar > p {
    padding: 15px;
    color: #000;
    margin: 0;
    cursor: pointer;
}

.sidebar-bar > p:first-child {
    margin-top: 20px;
}

.sidebar-bar > p:hover {
    color: var(--primary);
    background-color: var(--secondary);
}

.sidebar-bar > p:focus {
    color: var(--primary);
    background-color: var(--secondary);
}
.card-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;

    width: 90%;

    min-height: 230px;
    height: 230px;
    max-height: 230px;
    border-radius: 25px;

    max-width: 500px;
    margin-top: 14px;
    padding: 10px;
    box-shadow: var(--secondary-shadow);

    position: relative;
    background-size: cover;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-blend-mode: overlay;
}



.card-header {
    padding: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--text-light);
    color: var(--text-light);
    font-weight: 400;
}

.card-show {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    font-weight: 400;
    color: var(--text-light);
    font-size: 0.8rem;

    position: absolute;
    bottom: 10px;
}
.link-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    color: var(--text-light);

    position: relative;
    margin-top: 3px;
}

.link-container p {
    margin: 2px;
    margin-bottom: 1px;
    font-weight: 500;
    font-size: 0.9rem;
}

.link-container a {
    color: var(--text-light);
    font-size: 0.8rem;
    padding: 5px;
    width: 70%;
}

.link-container a:visited {
    color: var(--text-light);
}

.link-copy {
    position: absolute;
    margin-top: 5px;
    margin-right: 7px;
    top: 10px;
    right: 30px;
    font-size: 1.3rem;
}

.link-share {
    position: absolute;
    margin-top: 5px;
    top: 10px;
    right: 10px;
    margin-left: 7px;
    font-size: 1.3rem;
}
.toast-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    margin-bottom: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    background-image: linear-gradient(100deg,rgba(255, 255, 255, 0.95), rgba(232, 254, 255, 0.95), rgba(255, 253, 253, 0.7));
    padding: 5px;

    width: 40%;
    min-width: 250px;

    box-shadow: var(--regular-shadow);
    

    border-radius: 10px;
    transition: all 1s ease;
    z-index: 10;
}

.toast-container.hidden {
    opacity: 0;
    z-index: -1;
    transition: opacity 1s ease, z-index 1s ease 1s;
}

.toast-header {
    display: -webkit-flex;
    display: flex;
    color: var(--text);
    -webkit-align-items: center;
            align-items: center;
    font-weight:  bold;
    font-size: 0.8rem;
}

.toast-header > p {
    margin: 0;
}

p.toast-close {
    padding: 5px;
    margin-right: 10px;
    cursor: pointer;
    color: black;
}

.toast-main {
    font-family: Consolas,monaco,monospace;
    font-size: 0.75rem; 
}
.bible-container {
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex: 1 1;
            flex: 1 1;

    -webkit-align-items: center;

            align-items: center;
    
    color: var(--text);
    background-color: var(--background);

    background-size: cover;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
}

.bible-wrapper{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex: 1 1;
            flex: 1 1;
    width: 100%;
    -webkit-backdrop-filter: blur(30px);
            backdrop-filter: blur(30px);
    margin-top: 60px;
}

.bible-header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 90%;
    max-width: 500px;
    -webkit-align-self: center;
            align-self: center;
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.9);
}

.bible-header h4 {
    margin: 0;
    color: var(--primary);
}

.bible-date {
    margin: 0;
    padding: 3px;
    font-family: var(--secondary-font);
    font-size: 0.8rem;
    font-weight: 400;
}

.bible-selector {
    display: -webkit-flex;
    display: flex;
    margin-top: 15px;
    color: var(--primary);
    -webkit-align-items: center;
            align-items: center;
}

.bible-selector select {
    padding: 3px;
    border: none;
    font-family: var(--secondary-font);
    font-weight: 500;
    max-width: 90%;
    background-color: #ffffff00;
}

.bible-pg {
    margin: 0;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    font-family: var(--primary-font);
    font-weight: 400;
    font-size: 0.7rem;
}

.bible-main-wrapper {
    width: 90%;
    max-width: 500px;
    -webkit-align-self: center;
            align-self: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.bible-main {
    display: -webkit-flex;
    display: flex;
    width: 90%;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 10px;
    padding: 10px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.9);
}

.bible-main h3 {
    margin: 0;
    color: var(--primary);
}

.bible-scrip {
    margin: 0;
    padding: 3px;
    border: none;
    font-family: var(--secondary-font);
    color: var(--text-dark);
    font-weight: 500;
}

.bible-pgLeft, .bible-pgRight {
    position: fixed;
    bottom: 0;
    margin-bottom: 5%;
    background-color: var(--primary);
    width: 50px;
    height: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    color: var(--text-light);
    border-radius: 50%;
    opacity: 0.8;
}

.bible-pgLeft {
    left: 0;
    margin-left: 5%;
}

.bible-pgRight {
    right: 0;
    margin-right: 5%;
}

.bible-pgLeft svg, .bible-pgRight svg {
    font-size: 1.6rem;
}

