.link-container {
    display: flex;
    flex-direction: column;
    color: var(--text-light);

    position: relative;
    margin-top: 3px;
}

.link-container p {
    margin: 2px;
    margin-bottom: 1px;
    font-weight: 500;
    font-size: 0.9rem;
}

.link-container a {
    color: var(--text-light);
    font-size: 0.8rem;
    padding: 5px;
    width: 70%;
}

.link-container a:visited {
    color: var(--text-light);
}

.link-copy {
    position: absolute;
    margin-top: 5px;
    margin-right: 7px;
    top: 10px;
    right: 30px;
    font-size: 1.3rem;
}

.link-share {
    position: absolute;
    margin-top: 5px;
    top: 10px;
    right: 10px;
    margin-left: 7px;
    font-size: 1.3rem;
}