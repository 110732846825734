@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');

body{

  --primary: #E62727;
  --primary-variant: #FF4545;

  /* --secondary: #841BF7; */
  /* --secondary-variant: #451AED; */
  
  /* --highlight: #ED1A7B; */
  --background: #ffffff;
  --nav-background: rgba(255,255,255,0.82);

  --text-dark: #0e0e0e;
  --text: #828282;
  --text-light: #ffffff;

  --link: #2879BF;

  --error: rgba(241, 162, 14, 0.945);

  --primary-font:'Roboto', sans-serif;
  --secondary-font: 'Montserrat', sans-serif;
  --regular-shadow: 0px 3px 6px 0px rgba(0,0,0,0.32);
  --secondary-shadow: 10px 3px 6px 1px rgba(0,0,0,0.32);
}


html,
body, #root {
    width: 100%;
    height: 100%;

    font-family: var(--primary-font);
    font-weight: 300;
}

.App {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100%;
    height: auto;
    overflow: hidden;
}