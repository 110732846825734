.l-container {
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);

}

.l-container.hidden {
    display: none;
}

.l-balls svg {
    width: 100px;
}

[id="Ball 1"] {
    animation: pulse 0.6s alternate-reverse 0s infinite;
}

[id="Ball 2"] {
    animation: pulse 0.6s alternate-reverse 0.1s infinite;
}
[id="Ball 3"] {
    animation: pulse 0.6s alternate-reverse 0.2s infinite;
}
[id="Ball 4"] {
    animation: pulse 0.6s alternate-reverse 0.3s infinite;
}
[id="Ball 5"] {
    animation: pulse 0.6s alternate-reverse 0.4s infinite;
}
[id="Ball 6"] {
    animation: pulse 0.6s alternate-reverse 0.5s infinite;
}
[id="Ball 7"] {
    animation: pulse 0.6s alternate-reverse 0.6s infinite;
}

@keyframes pulse{
    from{
        fill: rgba(0, 0, 0, 0);
    }
    to{
        fill: var(--primary);
    }
}