.custom-input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.custom-input-container > label {
    font-weight: 500;
}

.custom-input-container > input {
    outline: none;
    border: none;
    padding: 5px;
    color: var(--text-dark);
    font-family: var(--secondary-font);
    font-weight: 500;
    border-bottom: 2px solid var(--primary);

    background-color: #fafafa;
}

.custom-input-container > input:focus {
    background-color: rgb(233, 233, 233);
}

@media only screen and (max-width: 600px) {
    .custom-input-container > input {
        border-bottom: 1px solid var(--primary);
    }
  }